import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: window.OKTA_ISSUER,
  clientId: window.OKTA_CLIENTID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
  tokenManager: { storage: "sessionStorage" }
});

export default oktaAuth;
