import React from 'react';
// import spritelogo from '@trv-ebus/tds-icons/dist/assets/icons/logos/symbol-sprites.svg';
import 'svgxuse/svgxuse';
import { TdsGlobalHeader } from '@trv-tds/react'; 

function IdentityHeader() {

  const appName = "Property Risk Toolworks";

  return (
  <TdsGlobalHeader homehref="#" homelabel="Home" product={appName}></TdsGlobalHeader>
  );
}


export default IdentityHeader;