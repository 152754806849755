import PrmXApiClient from './PrmXApiClient';

const IsDisClaimerRequired = async (token) => {
    let isloading = false; let iserror= false;
    let data = {};
    try {
          isloading = true;
          const res =  await PrmXApiClient.IsDisclaimerRequired({token});
          if(res && res.operationStatus && res.operationStatus.statusCode === "0"){
                data = res && res.data;
               
          }
          else{
                iserror = true;
              
          }  
          isloading = false;
         
    }
    catch(err){
        iserror = true;
        isloading = false;
        console.log("Unexpected error occured during IsDisclaimerRequired call: " + err.message);
    }   
    return { isloading, iserror, data }; 
};

const SaveAcceptance = async (userId, currentTCVersion) => {
    let isloading = false;  let iserror= false;  
    const acceptance = { userId, acceptedVersion: currentTCVersion};
    try  {
         isloading = true;
         var res = await PrmXApiClient.SaveAcceptance(acceptance);        
         if (!(res && res.operationStatus && res.operationStatus.statusCode === "0")) {
            iserror = true;          
         }         
         isloading = false;         
     }
     catch(err){
        iserror = true;
        isloading = false;    
        console.log("Unexpected error occured during SaveAcceptance call: " + err.message);                
    }   

    return { isloading, iserror };        
};

const PrmXApiAdapter =  { IsDisClaimerRequired  , SaveAcceptance };
export default PrmXApiAdapter;

