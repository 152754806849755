import React, { Fragment, useState, useEffect } from "react";
import IdentityHeader from "./components/shared/IdentityHeader";
import queryString from "query-string";
import PrmXApiAdapter from "./services/prmXApi/PrmXApiAdapter";
import { isEmpty } from "lodash";
import "./App.css";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import ErrorBoundary from "../src/components/error/ErrorBoundary";
import { Security, LoginCallback } from "@okta/okta-react";
import oktaAuth from "./oktaConfig";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const ErrorPage = React.lazy(() => import("./components/error/ErrorPage"));
const UnAuthenticatedApp = React.lazy(() =>
  import("./components/main/UnAuthenticatedApp")
);
const AuthenticatedApp = React.lazy(() =>
  import("./components/main/AuthenticatedApp")
);
const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.href = window.location.origin + originalUri;
};
try {
  const accessObject = JSON.parse(
    window.sessionStorage.getItem("okta-token-storage")
  );
  if (accessObject) {
    let accessToken = accessObject.accessToken.accessToken;
    axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
  }
} catch (err) {
  console.log("Error while setting authorization header", err);
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [appContext, setAppContext] = useState({
    userId: "",
    sai: "",
    isDisclaimerRequired: false,
    currentTCVersion: "",
  });
  const accessObject = JSON.parse(
    window.sessionStorage.getItem("okta-token-storage")
  );
  let accessToken = accessObject?.accessToken?.accessToken;

  useEffect(() => {
    async function getAppContext() {
      const requestValues = queryString.parse(window.location.search);
      const token = requestValues && requestValues.token;

      if (accessToken) {
        if (!isEmpty(token)) {
          let isauthenticated = true;

          const { isloading, iserror, data } =
            await PrmXApiAdapter.IsDisClaimerRequired(token);
          const isauthorized =
            data && !isEmpty(data.userId) && !isEmpty(data.sai) ? true : false;

          setIsError(iserror);
          setIsLoading(isloading);
          if (isauthenticated && isauthorized) {
            setAppContext(data);
            setIsAuthenticated(isauthenticated);
            setIsAuthorized(isauthorized);
          }
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      } else if (!accessObject && !accessToken) {
        const redirecturi = window.location.pathname + window.location.search;
        oktaAuth.signInWithRedirect({ originalUri: redirecturi });
      }
    }
    getAppContext();
  }, [accessToken]);

  return (
    <ErrorBoundary>
      <Fragment>
        <IdentityHeader userId={appContext.userId} />
        <div className="tds-body">
          <div className="tds-container">
            <Router>
              <Security
                restoreOriginalUri={restoreOriginalUri}
                oktaAuth={oktaAuth}
              >
                <React.Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    {!isAuthenticated && (
                      <Route
                        path="/login/callback"
                        element={<LoginCallback />}
                      />
                    )}
                    {isError && <Route path="/" element={<ErrorPage />} />}
                    {isLoading && (
                      <Route path="/" element={<LoadingSpinner />} />
                    )}
                    {isAuthenticated &&
                      isAuthorized &&
                      !isLoading &&
                      !isError && (
                        <Route
                          path="/"
                          element={<AuthenticatedApp appContext={appContext} />}
                        />
                      )}
                    {!isError && !isLoading && !isAuthorized && (
                      <Route path="/" element={<UnAuthenticatedApp />} />
                    )}
                  </Routes>
                </React.Suspense>
              </Security>
            </Router>
          </div>
        </div>
      </Fragment>
    </ErrorBoundary>
  );
}

export default App;
