import React from 'react';
import PropTypes from 'prop-types';

const ErrorPage = (props) => {
    const pageTitle = (props.error && props.error.title) ||  "We're Sorry";
    const pageContent = (props.error && props.error.message) || 
    'Property Risk Toolworks is currently unavailable. We apologize for the inconvenience and we are working on the issue. Please close this browser window and try back soon.';
   
     const contentStyle = {
        border: "1px solid lightgrey", 
        padding: "20px",      
        wordWrap: "normal",
        color: '#46494D',        
        fontSize: "18px"      
      };

      const headerTitleStyle = {           
          margin: "50px"
      };        
  
      return (
        <>  
        <div style={headerTitleStyle}>
          <h1 className="tds-h1--article" data-testid="pagetitle">{pageTitle}</h1>
          <p style={ contentStyle } data-testid="pagecontent">{pageContent}</p> 
        </div>
        </>
    );
};

ErrorPage.propTypes = {
  error: PropTypes.object 
};

export default ErrorPage;

