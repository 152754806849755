import React from 'react';
import ErrorPage from './ErrorPage';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.  
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log("Internal component error occured: " + error.message);      
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ErrorPage/>;
      }
  
      return this.props.children; 
    }
  }

  ErrorBoundary.propTypes= { 
      children: PropTypes.any.isRequired
   };

  export default ErrorBoundary;