import axios from 'axios';

const IsDisclaimerRequired = (data) =>  { 
   return sendPost("/api/isAgentDisclaimerRequired", data); 
};

const SaveAcceptance =  (data) => {  
   return sendPost("/api/saveAgentAcceptance", data);   
};

const sendPost = (url, data) => { 
  
  return axios.post(url, data).then(res => {        
        return Promise.resolve(res && res.data);
      })
      .catch(err => { 
        return Promise.reject(err);
  });
  
};

const PrmXApiClient =  { IsDisclaimerRequired,  SaveAcceptance };
export default PrmXApiClient;